import React from "react";

interface ExclamationCircleIconInterface {
  className?: string;
  width?: number;
  height?: number;
  svgFill?: string;
  fill?: string;
}
const ExclamationCircleIcon = ({
  width = 22,
  height = 22,
  fill = "#FA9E33",
  svgFill = "none",
  className = "",
}: ExclamationCircleIconInterface) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill={svgFill}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9989 10.9999C21.9989 17.075 17.0741 21.9999 10.999 21.9999C4.92387 21.9999 -0.000976562 17.075 -0.000976562 10.9999C-0.000976562 4.92484 4.92387 0 10.999 0C17.0741 0 21.9989 4.92484 21.9989 10.9999ZM11.9835 10.6318L12.5329 6.23649C12.6482 5.31443 11.9292 4.5 11 4.5C10.0708 4.5 9.3518 5.31443 9.46706 6.23649L10.0165 10.6318C10.0785 11.1278 10.5001 11.5 11 11.5C11.4999 11.5 11.9215 11.1278 11.9835 10.6318ZM11.0039 17.5C11.9704 17.5 12.7539 16.7165 12.7539 15.75C12.7539 14.7835 11.9704 14 11.0039 14C10.0374 14 9.25391 14.7835 9.25391 15.75C9.25391 16.7165 10.0374 17.5 11.0039 17.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default ExclamationCircleIcon;
