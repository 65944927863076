"use client";

import { AppProgressBar as ProgressBar } from "next-nprogress-bar";

const ClientsideProgressbar = () => {
  const color = "#0A2FFF";
  const height = "3px";
  return (
    <ProgressBar
      height={height}
      options={{
        easing: "ease",
        speed: 300,
        trickleSpeed: 100,
      }}
      style={`
          #nprogress {
            pointer-events: none;
          }

          #nprogress .bar {
            background: linear-gradient(90deg, #459541 0%, #F5AD46 50%, #78528C 92.12%, #694794 100%);

            position: fixed;
            z-index: 1031;
            top: 0;
            left: 0;

            width: 100%;
            height: ${height};
          }

          /* Fancy blur effect */
          #nprogress .peg {
            display: block;
            position: absolute;
            right: 0px;
            width: 100px;
            height: 100%;
            box-shadow: 0 0 10px ${color}, 0 0 5px ${color};
            opacity: 1.0;

            -webkit-transform: rotate(3deg) translate(0px, -4px);
                -ms-transform: rotate(3deg) translate(0px, -4px);
                    transform: rotate(3deg) translate(0px, -4px);
          }

          .nprogress-custom-parent {
            overflow: hidden;
            position: relative;
          }

          .nprogress-custom-parent #nprogress .spinner,
          .nprogress-custom-parent #nprogress .bar {
            position: absolute;
          }
        `}
    />
  );
};

export default ClientsideProgressbar;
