import React, { ReactNode } from "react";

import clsx from "clsx";
import css from "./index.module.css";

interface AcceptInviteButtonInterface {
  label?: ReactNode;
  className?: string;
  onClick?: () => void;
}
const AcceptInviteButton = ({ label, onClick, className }: AcceptInviteButtonInterface) => {
  return (
    <div onClick={onClick} className={clsx(css.wrapper, className)} data-cy="accept-invit-btn">
      {label}
    </div>
  );
};
export default AcceptInviteButton;
