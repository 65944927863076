import React from "react";

interface ExclamationIconInterface {
  className?: string;
  width?: number;
  height?: number;
  svgFill?: string;
  fill?: string;
}
const ExclamationIcon = ({
  width = 22,
  height = 22,
  fill = "#EF476F",
  svgFill = "none",
  className = "",
}: ExclamationIconInterface) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill={svgFill}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3597_14862)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.27944 1.15674C10.0636 -0.385582 12.1278 -0.38558 12.912 1.15674L21.8402 18.7175C22.5922 20.1966 21.5943 22 20.0239 22H2.16751C0.597104 22 -0.400746 20.1966 0.351246 18.7175L9.27944 1.15674ZM11.9835 12.1318L12.5329 7.73649C12.6482 6.81443 11.9292 6 11 6C10.0708 6 9.3518 6.81443 9.46706 7.73649L10.0165 12.1318C10.0785 12.6278 10.5001 13 11 13C11.4999 13 11.9215 12.6278 11.9835 12.1318ZM11.0039 19C11.9704 19 12.7539 18.2165 12.7539 17.25C12.7539 16.2835 11.9704 15.5 11.0039 15.5C10.0374 15.5 9.25391 16.2835 9.25391 17.25C9.25391 18.2165 10.0374 19 11.0039 19Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_3597_14862">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ExclamationIcon;
