"use client";

import React from "react";
import { RelayEnvironmentProvider } from "react-relay";

import { createEnvironment } from "@relay/environment";

import { ToasterAlertContainer } from "./shared/ToasterAlert";

interface RootEnvironmentProviderProps {
  children: React.ReactNode;
  token: string;
}

const RootEnvironmentProvider: React.FC<RootEnvironmentProviderProps> = ({ children, token }) => {
  const environment = createEnvironment(token);

  return (
    <RelayEnvironmentProvider environment={environment}>
      <ToasterAlertContainer />
      {children}
    </RelayEnvironmentProvider>
  );
};

export default RootEnvironmentProvider;
