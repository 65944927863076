import React from "react";

interface QuestionCircleIconInterface {
  className?: string;
  width?: number;
  height?: number;
  svgFill?: string;
  fill?: string;
}

const QuestionCircleIcon = ({
  width = 22,
  height = 22,
  fill = "#0F0518",
  svgFill = "none",
  className = "",
}: QuestionCircleIconInterface) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill={svgFill}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9989 10.9999C21.9989 17.075 17.0741 21.9999 10.999 21.9999C4.92387 21.9999 -0.000976562 17.075 -0.000976562 10.9999C-0.000976562 4.92484 4.92387 0 10.999 0C17.0741 0 21.9989 4.92484 21.9989 10.9999ZM11.0842 5.50177C10.724 5.48659 10.3663 5.56908 10.0491 5.7405C9.73191 5.91193 9.46695 6.16593 9.28226 6.47561C9.09758 6.78528 9.00005 7.13913 9 7.4997C8.99992 8.05198 8.55213 8.49963 7.99985 8.49955C7.44756 8.49946 6.99992 8.05168 7 7.49939C7.00011 6.77826 7.19517 6.07057 7.56453 5.45121C7.93389 4.83185 8.46382 4.32386 9.09824 3.98101C9.73265 3.63815 10.448 3.47318 11.1685 3.50355C11.8889 3.53392 12.5878 3.7585 13.1911 4.15353C13.7945 4.54855 14.2798 5.09933 14.5957 5.74758C14.9116 6.39582 15.0464 7.11742 14.9859 7.836C14.9253 8.55458 14.6716 9.24343 14.2516 9.82964C13.8316 10.4158 13.2609 10.8776 12.6 11.1661C12.4217 11.2439 12.2699 11.3721 12.1633 11.5348C12.0568 11.6976 12 11.888 12 12.0826C12 12.6348 11.5523 13.0826 11 13.0825C10.4477 13.0825 9.99999 12.6348 10 12.0825C10 11.4988 10.1703 10.9278 10.49 10.4394C10.8098 9.95101 11.265 9.56652 11.8 9.33303C12.1305 9.1888 12.4158 8.95792 12.6258 8.66482C12.8358 8.37172 12.9626 8.02729 12.9929 7.668C13.0232 7.30871 12.9558 6.94791 12.7978 6.62379C12.6399 6.29967 12.3972 6.02428 12.0956 5.82676C11.7939 5.62925 11.4445 5.51696 11.0842 5.50177ZM11.0039 18.5C11.9704 18.5 12.7539 17.7165 12.7539 16.75C12.7539 15.7835 11.9704 15 11.0039 15C10.0374 15 9.25391 15.7835 9.25391 16.75C9.25391 17.7165 10.0374 18.5 11.0039 18.5Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9999 10.9999C21.9999 17.075 17.075 21.9999 10.9999 21.9999C4.92484 21.9999 0 17.075 0 10.9999C0 4.92484 4.92484 0 10.9999 0C17.075 0 21.9999 4.92484 21.9999 10.9999ZM11.0852 5.50177C10.725 5.48659 10.3673 5.56908 10.0501 5.7405C9.73289 5.91193 9.46792 6.16593 9.28324 6.47561C9.09856 6.78528 9.00103 7.13913 9.00098 7.4997C9.00089 8.05198 8.55311 8.49963 8.00082 8.49955C7.44854 8.49946 7.00089 8.05168 7.00098 7.49939C7.00109 6.77826 7.19614 6.07057 7.56551 5.45121C7.93487 4.83185 8.4648 4.32386 9.09921 3.98101C9.73363 3.63815 10.4489 3.47318 11.1694 3.50355C11.8899 3.53392 12.5888 3.7585 13.1921 4.15353C13.7954 4.54855 14.2807 5.09933 14.5967 5.74758C14.9126 6.39582 15.0474 7.11742 14.9868 7.836C14.9263 8.55458 14.6726 9.24343 14.2526 9.82964C13.8326 10.4158 13.2619 10.8776 12.601 11.1661C12.4226 11.2439 12.2709 11.3721 12.1643 11.5348C12.0577 11.6976 12.001 11.888 12.001 12.0826C12.001 12.6348 11.5532 13.0826 11.001 13.0825C10.4487 13.0825 10.001 12.6348 10.001 12.0825C10.001 11.4988 10.1713 10.9278 10.491 10.4394C10.8107 9.95101 11.266 9.56652 11.801 9.33303C12.1314 9.1888 12.4168 8.95792 12.6268 8.66482C12.8368 8.37172 12.9636 8.02729 12.9939 7.668C13.0242 7.30871 12.9568 6.94791 12.7988 6.62379C12.6409 6.29967 12.3982 6.02428 12.0965 5.82676C11.7949 5.62925 11.4454 5.51696 11.0852 5.50177ZM11.0049 18.5C11.9714 18.5 12.7549 17.7165 12.7549 16.75C12.7549 15.7835 11.9714 15 11.0049 15C10.0384 15 9.25488 15.7835 9.25488 16.75C9.25488 17.7165 10.0384 18.5 11.0049 18.5Z"
        fill={fill}
      />
    </svg>
  );
};
export default QuestionCircleIcon;
