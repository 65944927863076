import clsx from "clsx";
import React, { ReactNode } from "react";
import { Icons, ToastContainer, toast } from "react-toastify";
import { ReactElement } from "rehype-react/lib";

import CheckCircleIcon from "@assets/icons/CheckCircleIcon";
import ExclamationCircleIcon from "@assets/icons/ExclamationCircleIcon";
import ExclamationIcon from "@assets/icons/ExclamationIcon";
import QuestionCircleIcon from "@assets/icons/QuestionCircleIcon";

import AcceptInviteButton from "../Buttons/AcceptInviteButton";
import GotoDiscordButton from "../Buttons/GotoDiscordButton";
import cssMsg from "./Message.module.css";
import cssNotif from "./Notification.module.css";

const ToasterNotifIcons: { [index: string]: ReactElement } = {
  default: <QuestionCircleIcon fill="#0F0518" />,
  success: <CheckCircleIcon fill="#02C372" />,
  warning: <ExclamationCircleIcon fill="#FA9E33" />,
  error: <ExclamationIcon fill="#EF476F" />,
};

interface ToasterAlertInterface {
  message: string;
}

const ToasterAlertDefault = ({ message }: ToasterAlertInterface) => {
  toast(<div data-cy="toast-default">{message}</div>);
};
const ToasterAlertError = ({ message }: ToasterAlertInterface) => {
  toast.error(<div data-cy="toast-error">{message}</div>, {
    icon: Icons.warning,
  });
};
const ToasterAlertWarning = ({ message }: ToasterAlertInterface) => {
  toast.warning(<div data-cy="toast-warning">{message}</div>);
};

const ToasterAlertSuccess = ({ message }: ToasterAlertInterface) => {
  toast.success(<div data-cy="toast-success">{message}</div>, {
    // icon: <Image alt="success" src="/icons/checkmark-circle.svg" width={22} height={22} />,
  });
};

const ToasterAlertContainer = () => {
  return (
    <ToastContainer
      position="bottom-center"
      closeButton={false}
      autoClose={3000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      className={"test-cls-toaster"}
      theme="colored"
    />
  );
};

export interface InlineNotificationInterface {
  variant: "default" | "success" | "error" | "warning";
  inModals?: boolean;
  message: ReactNode;
  className?: string;
}
const InlineNotification = ({
  variant = "default",
  inModals = false,
  message,
  className = "",
}: InlineNotificationInterface) => {
  return (
    <div className={clsx(cssNotif.wrapper, cssNotif[variant], className)} data-cy={"inline-notification"}>
      <div
        className={clsx({ [cssNotif.content]: true, [cssNotif.contentModals]: inModals, [cssNotif[variant]]: true })}
      >
        <div className={clsx(cssNotif.iconWrap)}>{ToasterNotifIcons[variant]}</div>
        <div className={clsx(cssNotif.message)}>{message}</div>
      </div>
    </div>
  );
};

export interface InlineMessageInterface {
  variant: "info";
  message: ReactNode;
  startIcon?: ReactElement;
  className?: string;
}
const InlineMessage = ({ variant = "info", startIcon, message, className = "" }: InlineMessageInterface) => {
  return (
    <div className={clsx(cssMsg.wrapper, cssMsg[variant], className)}>
      <div className={clsx({ [cssMsg.content]: true, [cssMsg[variant]]: true })}>
        <div className={clsx(cssMsg.iconWrap)}>{startIcon}</div>
        <div className={clsx(cssMsg.message)}>{message}</div>
      </div>
    </div>
  );
};

interface InviteInlineNotificationInterface {
  message: ReactNode;
  onAccept?: () => void;
  acceptLabel?: string;
}
const InviteInlineNotification = ({
  message = "",
  acceptLabel = "Accept",
  onAccept = () => {},
}: InviteInlineNotificationInterface) => {
  return (
    <InlineNotification
      variant="warning"
      message={
        <div className={cssNotif.invitCont}>
          <div className={cssNotif.message}>{message}</div>
          <AcceptInviteButton onClick={onAccept} label={acceptLabel} />
        </div>
      }
    />
  );
};

interface InlineSocialNotificationInterface {
  variant?: InlineNotificationInterface["variant"];
  message: ReactNode;
  label?: string;
  url?: string;
  className?: string;
}
const InlineSocialNotification = ({
  message = "",
  url,
  label = "Go to Discord",
  className = "",
  variant,
}: InlineSocialNotificationInterface) => {
  return (
    <InlineNotification
      variant={variant || "default"}
      className={className}
      message={
        <div className={cssNotif.invitCont}>
          <div className={cssNotif.message}>{message}</div>
          <GotoDiscordButton label={label} url={url} target={"_blank"} />
        </div>
      }
    />
  );
};

export {
  ToasterAlertSuccess,
  ToasterAlertDefault,
  ToasterAlertError,
  ToasterAlertWarning,
  ToasterAlertContainer,
  InlineNotification,
  InlineMessage,
  InviteInlineNotification,
  InlineSocialNotification,
};
