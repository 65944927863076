import React from "react";

interface CheckCircleIconInterface {
  className?: string;
  width?: number;
  height?: number;
  svgFill?: string;
  fill?: string;
}
const CheckCircleIcon = ({
  width = 22,
  height = 22,
  fill = "#FBFBFC",
  svgFill = "none",
  className = "",
}: CheckCircleIconInterface) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill={svgFill}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11ZM16.7033 8.40055C17.0346 7.9587 16.945 7.33191 16.5032 7.00057C16.0613 6.66924 15.4345 6.75883 15.1032 7.20069C14.4979 8.00787 12.9361 9.89596 11.503 11.6082C10.7912 12.4585 10.1183 13.257 9.62329 13.843L9.43191 14.0695C9.1927 13.8813 8.91401 13.6606 8.62215 13.4267C7.76645 12.7411 6.85819 11.9897 6.46101 11.5925C6.07049 11.202 5.43732 11.202 5.0468 11.5925C4.65628 11.983 4.65628 12.6162 5.0468 13.0067C5.53718 13.4971 6.53902 14.3204 7.37152 14.9875C7.79747 15.3288 8.195 15.6418 8.48588 15.8693C8.6314 15.9831 8.75044 16.0757 8.83324 16.14L8.9291 16.2143L8.95439 16.2338L8.96098 16.2389L8.96271 16.2403L8.96318 16.2406L8.96331 16.2407C8.96335 16.2408 8.96338 16.2408 9.57409 15.4489L8.96338 16.2408C9.38623 16.5669 9.99069 16.5032 10.3363 16.0962L9.57409 15.4489C10.3363 16.0962 10.3364 16.0962 10.3364 16.0961L10.3366 16.0959L10.3373 16.0951L10.3401 16.0918L10.3511 16.0788L10.394 16.0282L10.5577 15.8351C10.6993 15.668 10.9029 15.4275 11.1511 15.1337C11.6474 14.5461 12.3224 13.7452 13.0366 12.8919C14.4555 11.1968 16.0583 9.2607 16.7033 8.40055Z"
        fill={fill}
      />
    </svg>
  );
};

export default CheckCircleIcon;
