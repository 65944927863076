import Cookies from "js-cookie";
import {
  type CacheConfig,
  type GraphQLResponse,
  type RequestParameters,
  type UploadableMap,
  Environment,
  Network,
  QueryResponseCache,
  RecordSource,
  Store,
  Observable,
  Variables,
} from "relay-runtime";

import { SubscriptionClient } from "persisted-subscriptions-transport-ws";
import env from "@libs/publicEnv";
// import { createClient, type Sink } from "graphql-ws";

const IS_SERVER = typeof window === typeof undefined;
const CACHE_TTL = 30 * 1000; // 30 seconds, to resolve preloaded results

const getHTTPEndpoint = (): string => {
  return env.NEXT_PUBLIC_RELAY_ENDPOINT;
};

export async function networkFetch(
  request: RequestParameters,
  variables: Variables,
  token?: string,
  uploadables?: UploadableMap | null
): Promise<GraphQLResponse> {
  const HTTP_ENDPOINT = getHTTPEndpoint();
  if (!IS_SERVER) {
    token = Cookies.get("auth_token");
  }
  // remove null or undefined variables
  variables = Object.entries(variables)
    .filter(([_, value]) => value != null)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  const requestHeaders: HeadersInit = {
    Accept: "application/json",
  };
  if (token?.length ?? 0 > 0) {
    requestHeaders.Authorization = `Bearer ${token}`;
  }

  console.info(
    `[ ${new Date().toUTCString()}:${new Date().getMilliseconds()}] Fetching ${HTTP_ENDPOINT} (${request.name})`
  );

  let body;
  if (uploadables && Object.keys(uploadables).length > 0) {
    // This path will only be followed from our client.
    // Hence, it's okay to assume this comes from our window.
    if (!window.FormData) {
      throw new Error("Uploading files without `FormData` not supported.");
    }

    const formData = new FormData();
    if (request.text) {
      formData.append("query", request.text);
    }
    if (request.id) {
      formData.append("id", request.id);
    }
    formData.append("variables", JSON.stringify(variables));
    formData.append("operationName", request.name);

    Object.keys(uploadables).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
        formData.append(key, uploadables[key]);
      }
    });

    body = formData;
  } else {
    requestHeaders["Content-Type"] = "application/json";

    body = JSON.stringify({
      query: request.text,
      variables,
      operationName: request.name,
      id: request.id,
    });
  }

  const resp = await fetch(HTTP_ENDPOINT, {
    method: "POST",
    headers: requestHeaders,
    body: body,
    // TODO: using 'cors' mode generate an error, it's not implemented by wrangler yet
    // mode: "cors",
  });

  const json: any = await resp.json();

  console.info(`[ ${new Date().toUTCString()}:${new Date().getMilliseconds()}] Fetched (${request.name})`);

  return json;
}

function createCache(): QueryResponseCache {
  const responseCache = new QueryResponseCache({
    size: 100,
    ttl: CACHE_TTL,
  });
  return responseCache;
}

function createNetwork(responseCache: QueryResponseCache, token?: string) {
  const fetchResponse = async (
    params: RequestParameters,
    variables: Variables,
    cacheConfig: CacheConfig,
    uploadables?: UploadableMap | null
  ) => {
    const isQuery = params.operationKind === "query";
    const cacheKey = params.id ?? params.cacheID;
    const forceFetch = cacheConfig && cacheConfig.force;
    if (responseCache != null && isQuery && !forceFetch) {
      const fromCache = responseCache.get(cacheKey, variables);
      if (fromCache != null) {
        return Promise.resolve(fromCache);
      }
    }

    let response = await networkFetch(params, variables, token, uploadables);
    if (responseCache != null && isQuery) {
      responseCache.set(cacheKey, variables, response);
    }
    return response;
  };

  let network;
  const HTTP_ENDPOINT = getHTTPEndpoint();
  if (IS_SERVER) {
    network = Network.create(fetchResponse);
  } else if (typeof window !== "undefined") {
    const subscriptionClient = new SubscriptionClient(
      (HTTP_ENDPOINT as string)?.replace(/^http:\/\//, "ws://")?.replace(/^https:\/\//, "wss://"),
      {
        reconnect: true,
        lazy: true,
        connectionParams: {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        },
      }
    );
    const subscribe = (request: any, variables: any) => {
      const subscribeObservable = subscriptionClient.request({
        query: request.text,
        id: request.id,
        operationName: request.name,
        variables,
      });
      // Important: Convert subscriptions-transport-ws observable type to Relay's
      return Observable.from(subscribeObservable as any);
    };
    network = Network.create(fetchResponse, subscribe);
  }

  return network;
}

let frontendEnvironment: Environment;
let backendUnauthedEnvironment: Environment; // We only use it to cache the results

export function createEnvironment(token?: string, records?: any) {
  if (!IS_SERVER && frontendEnvironment) {
    return frontendEnvironment;
  } else if (IS_SERVER && !token && backendUnauthedEnvironment) {
    return backendUnauthedEnvironment;
  }
  const cache = createCache();
  const environment = new Environment({
    network: createNetwork(cache, token)!,
    store: new Store(new RecordSource(records)),
    isServer: IS_SERVER,
    options: {
      cache,
    },
  });
  if (!IS_SERVER) {
    frontendEnvironment = environment;
  } else if (!token) {
    backendUnauthedEnvironment = environment;
  }
  return environment;
}
