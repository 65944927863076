import Link from "next/link";
import React from "react";

import DiscordLogo from "@assets/icons/DiscordLogo";

import css from "./GotoDiscordButton.module.css";

interface GotoDiscordButtonInterface {
  url?: string;
  label?: string;
  target: string;
}
const GotoDiscordButton = ({ label, url, target }: GotoDiscordButtonInterface) => {
  return (
    <>
      {url && (
        <Link href={url} className={css.wrapper} target={target}>
          <DiscordLogo fill="#0F0518" />
          <div className={css.label}>{label}</div>
        </Link>
      )}
      {!url && (
        <div className={css.wrapper}>
          <DiscordLogo fill="#0F0518" />
          <div className={css.label}>{label}</div>
        </div>
      )}
    </>
  );
};
export default GotoDiscordButton;
