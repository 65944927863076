type EnvType = {
  NEXT_PUBLIC_RELAY_ENDPOINT: string;
  WASMER_BASE_URL: string;
  NEXT_PUBLIC_INTERCOM_APP_ID?: string;
};

const getDefaultPublicRelayEndpoint = () => {
  if (window.location.host == "wasmer.wtf" || window.location.host == "localhost:3000") {
    return "https://registry.wasmer.wtf/graphql";
  }
  return "https://registry.wasmer.io/graphql";
};

const getPublicEnv = (): EnvType => {
  if (typeof window !== "undefined") {
    const env = (window as any).PROCESS_ENV;
    return {
      NEXT_PUBLIC_RELAY_ENDPOINT: env.NEXT_PUBLIC_RELAY_ENDPOINT || getDefaultPublicRelayEndpoint(),
      NEXT_PUBLIC_INTERCOM_APP_ID: env.NEXT_PUBLIC_INTERCOM_APP_ID,
      WASMER_BASE_URL: env.WASMER_BASE_URL || window.location.origin,
    };
  } else {
    // assert(process.env.WASMER_BASE_URL!, "Need to provide the WASMER_BASE_URL env var");
    // assert(process.env.NEXT_PUBLIC_RELAY_ENDPOINT!, "Need to provide the NEXT_PUBLIC_RELAY_ENDPOINT env var");
    // assert(process.env.NEXT_PUBLIC_RELAY_ENDPOINT!, "Need to provide the NEXT_PUBLIC_INTERCOM_APP_ID env var");
    return {
      NEXT_PUBLIC_RELAY_ENDPOINT: process.env.NEXT_PUBLIC_RELAY_ENDPOINT!,
      NEXT_PUBLIC_INTERCOM_APP_ID: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
      WASMER_BASE_URL: process.env.WASMER_BASE_URL!,
    };
  }
};

export default getPublicEnv() as EnvType;
